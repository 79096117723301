import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Comment } from '@mui/icons-material';
import { Fab, Typography, styled } from '@mui/material';

import TaskCard from './TaskCard';
import store from '../utilities/store';
import useQuery from '../utilities/useQuery';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import { getLocalDateString } from '../utilities/languages';
import { allStates, getStateBySlug } from '../utilities/states';
import CropInfoService from '../services/CropInfoService';
import { getRouteParams, makeTaskListingPageHref } from '../utilities/links';
import { SubHeadingWrapper } from './ExpertVideos';
import ClevertapService from '../services/ClevertapService';
import moment from 'moment';

const PageWrapper = styled('div')({
  padding: '0 15px 80px',
  background: '#fff',
});
const FutureTaskComing = styled('div')({
  padding: '10px',
  marginTop: '20px',
  borderRadius: '8px',
  color: '#fff',
  opacity: '0.8',
  background: "url('/taskPageWeatherBg.svg')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

function TaskListingPage({ setHeaderURLs, intl }) {
  const { loadingStatus, match, search } = useFetchData(
    TaskListingPage,
    store,
    setHeaderURLs,
    intl,
  );
  const query = useQuery();
  const mockDate = query.get('mockDate');
  const title = query.get('title');
  const task = query.get('task');
  const mockAllTasks = query.get('mockAllTasks');
  const mockScheduleName = query.get('mockScheduleName');
  const farmerAuthToken = query.get('farmerAuthToken');
  const [cropSchedule, setCropSchedule] = useState(null);
  const [loading, setLoading] = useState('started');
  const { slug, state, language } = getRouteParams(match, search);

  useEffect(() => {
    ClevertapService.pushEvent(`Page Viewed`, {
      Type: 'Task Listing',
    });
    CropInfoService.getTasks({
      state,
      language,
      cropName: slug,
      farmerAuthToken,
      mockDate,
      mockAllTasks,
      mockScheduleName,
    })
      .then((response) => {
        let schedule = response?.responseData?.[0];

        const tasks = schedule?.tasks?.map((task) => {
          moment.locale(language);
          let { startDateMillis, endDateMillis } = task;
          task['startDate'] = `${getLocalDateString(
            startDateMillis,
            language,
            'day',
          )} ${moment(startDateMillis).format('MMMM')}`;
          task['endDate'] = `${getLocalDateString(
            endDateMillis,
            language,
            'day',
          )} ${moment(endDateMillis).format('MMMM')}`;
          task['taskStatus'] = task?.title;

          return task;
        });

        setCropSchedule({ ...schedule, tasks });

        setTimeout(() => {
          const element = document.getElementById(task);
          element?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }, 500);
        setLoading('done');
      })
      .catch((e) => {
        setLoading('error');
        console.log('Failed to fetch Tasks: ', e);
      });
  }, [search]);

  if (loadingStatus === 'loading' || loading === 'started') return <Loader />;
  if (loadingStatus === 'error' || loading === 'error') return <ServerError />;
  if (!cropSchedule?.tasks?.length) {
    return <ServerError textId="label_no_tasks_found" />;
  }

  return (
    <PageWrapper>
      <SubHeadingWrapper>
        <FormattedMessage id="label_tasks" />
      </SubHeadingWrapper>
      <Typography sx={{ mt: '5px', fontSize: '14px', color: '#666666' }}>
        <FormattedMessage id="label_perform_below_task" />
      </Typography>

      {cropSchedule?.tasks?.map((task, taskIndex) => {
        if (task && task?.title) {
          return (
            <React.Fragment key={taskIndex}>
              <div style={{ marginTop: '20px' }}></div>
              <TaskCard
                index={1}
                task={task}
                match={match}
                search={search}
                showFullTask={true}
              />
            </React.Fragment>
          );
        }
        return null;
      })}

      <FutureTaskComing>
        <FormattedMessage id="label_come_again_for_task" />
      </FutureTaskComing>

      <Fab
        variant="extended"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          fontWeight: 700,
        }}
        href="ulink://android.agrostar.in/createPost"
        onClick={() =>
          ClevertapService.pushEvent(`Button Clicked`, {
            Type: 'Create Post',
          })
        }
      >
        <Comment sx={{ mr: 1, fontSize: '1.3rem' }} />
        <FormattedMessage id="label_need_expert_help" />
      </Fab>
    </PageWrapper>
  );
}

TaskListingPage.fetchData = async (match, search) => {
  const { language, state, slug } = getRouteParams(match, search);
  const query = new URLSearchParams(search);
  const farmerAuthToken = query.get('farmerAuthToken');

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    languageURLs[lang] = makeTaskListingPageHref({
      match,
      search,
      cropName: slug,
      language: lang,
      farmerAuthToken,
    });
  });

  const stateURLs = {};
  allStates.forEach((state) => {
    if (state.shop) {
      const lang = language === 'en' ? 'en' : state.language;
      stateURLs[state.slug] = makeTaskListingPageHref({
        match,
        search,
        cropName: slug,
        language: lang,
        state: state.slug,
      });
    } else {
      stateURLs[state.slug] = 'stateNa';
    }
  });

  return {
    initialData: {},
    titleId: 'label_tasks',
    seoData: {},
    languageURLs,
    stateURLs,
    disableAnalytics: true,
  };
};

export default injectIntl(TaskListingPage);
