import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import { AddCircleOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import styles from './styles';
import MockDate from './MockDate';
import store from '../utilities/store';
import slugify from '../utilities/slugify';
import useQuery from '../utilities/useQuery';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import ClevertapService from '../services/ClevertapService';
import { getLocalDateString } from '../utilities/languages';
import { allStates, getStateBySlug } from '../utilities/states';
import CropInfoService from '../services/CropInfoService';
import { CropsTabs, TabPanel, convertToDayStartMillis } from './CropsTabs';
import {
  getRouteParams,
  makeSelectYourCropsHref,
  makeCropInfoHref,
} from '../utilities/links';
import AndroidService from '../services/AndroidService';
import { getEnv } from '../utilities/getEnv';
import moment from 'moment';
import { getCropsList } from '../crop/CropListPage';

function CropInfoPage({ setHeaderURLs, intl }) {
  const { loadingStatus, match, search } = useFetchData(
    CropInfoPage,
    store,
    setHeaderURLs,
    intl,
  );
  const query = useQuery();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const userId = query.get('userId');
  const mockDate = query.get('mockDate');
  const farmerId = query.get('farmerId');
  const appLinks = query.get('appLinks');
  const role = query.get('role');
  const profile = query.get('profile');
  const source = query.get('source');
  const fieldAgentMobileNumber = query.get('fieldAgentMobileNumber');
  const showTasks = query.get('showTasks') === 'false' ? false : true;
  const showPosts = query.get('showPosts') === 'false' ? false : true;
  const mockAllTasks = query.get('mockAllTasks');
  const mockScheduleName = query.get('mockScheduleName');
  const farmerAuthToken = query.get('farmerAuthToken');
  const [cropSchedules, setCropSchedules] = useState([]);
  const { state, language } = getRouteParams(match, search);
  const [loading, setLoading] = useState('not_started');
  const [pageLoading, setPageLoading] = useState('started');
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      setIsAndroid(true);
    }
    ClevertapService.pushEvent(`Page Viewed`, {
      Type: 'Crop Info',
    });

    if (profile !== 'true' && role === 'FAG') {
      fieldAgentCropLogic();
    } else {
      // deeplink to /select-your-crops page using query param
      if (query.get('redirectTo') === 'selectYourCrops') {
        query.delete('redirectTo');
        window?.history?.replaceState(
          {},
          null,
          `/crop-info?${query.toString()}`,
        );
        return history.push(
          `${makeSelectYourCropsHref(
            match,
            search,
            language,
            state,
          )}&userId=${userId}&farmerId=${farmerId}&farmerAuthToken=${farmerAuthToken}&showHeader=false&appLinks=${appLinks}&role=${role}`,
        );
      } else {
        (async function () {
          setPageLoading('started');
          try {
            const allCropsTasks = {};
            const schedules = await CropInfoService.getMyCropsSchedule(
              language,
              farmerAuthToken,
              farmerId,
            );
            if (showTasks) {
              const taskResponse = await CropInfoService.getTasks({
                state,
                language,
                farmerAuthToken,
                mockDate,
                mockAllTasks,
                mockScheduleName,
              });
              taskResponse?.responseData?.forEach((cropTask) => {
                allCropsTasks[cropTask?.cropName] = cropTask;
              });
            }

            let newSchedules = schedules?.responseData?.map(async (crop) => {
              const { id, name, display, imageUrl, schedulesSupported } = crop;
              const cropSectionData = {
                schedulesSupported: schedulesSupported,
                inputsComplete: true,
                cropName: display,
                category: name,
                imageUrl: imageUrl
                  ? imageUrl + '?tr=h-60'
                  : '/icons/agrostar.png',
                cropId: id,
              };
              return allCropsTasks[name]
                ? {
                  ...cropSectionData,
                  ...allCropsTasks[name],
                  cropName: display,
                }
                : cropSectionData;
            });

            const cropSchedulesData = await Promise.all(newSchedules);
            const allTasksForToday = [];

            newSchedules = cropSchedulesData?.map((cropSchedule) => {
              let canAddId = true,
                totalTask = 0,
                totalCurrentTask = 0;
              moment.locale(language);

              cropSchedule['sowingDate'] = `${getLocalDateString(
                cropSchedule?.sowingDateMillis,
                language,
                'day',
              )} ${moment(cropSchedule?.sowingDateMillis).format(
                'MMMM',
              )} ${getLocalDateString(
                cropSchedule?.sowingDateMillis,
                language,
                'year',
              )}`;
              const newTasks = cropSchedule?.tasks?.map((task) => {
                let { startDateMillis, endDateMillis } = task;
                task['cropName'] = cropSchedule?.cropName;
                task['category'] = cropSchedule?.category;
                task['cropImageUrl'] = cropSchedule?.imageUrl;
                task['startDate'] = `${getLocalDateString(
                  startDateMillis,
                  language,
                  'day',
                )} ${moment(startDateMillis).format('MMMM')}`;
                task['endDate'] = `${getLocalDateString(
                  endDateMillis,
                  language,
                  'day',
                )} ${moment(endDateMillis).format('MMMM')}`;

                let todayMillis = query.get('mockDate')
                  ? new Date(query.get('mockDate').replace('-', '/')).getTime()
                  : new Date().getTime();
                startDateMillis = convertToDayStartMillis(startDateMillis);
                endDateMillis = convertToDayStartMillis(endDateMillis);
                todayMillis = convertToDayStartMillis(todayMillis);

                if (endDateMillis < todayMillis) {
                  task['taskStatus'] = 'oldTask';
                } else if (
                  canAddId &&
                  startDateMillis <= todayMillis &&
                  endDateMillis >= todayMillis
                ) {
                  totalCurrentTask++;
                  allTasksForToday.push(task);
                  canAddId = false;
                  task['taskStatus'] = 'currentTask';
                } else if (
                  startDateMillis <= todayMillis &&
                  endDateMillis >= todayMillis
                ) {
                  totalCurrentTask++;
                  allTasksForToday.push(task);
                  task['taskStatus'] = 'currentTask2';
                } else {
                  task['taskStatus'] = 'upcomingTask';
                }
                totalTask++;

                return task;
              });

              return {
                ...cropSchedule,
                tasks: newTasks,
                totalTask,
                totalCurrentTask,
              };
            });

            newSchedules = newSchedules
              ?.sort((a, b) => b.schedulesSupported - a.schedulesSupported)
              ?.sort((a, b) => b.totalTask - a.totalTask)
              ?.sort((a, b) => b.totalCurrentTask - a.totalCurrentTask);

            if (showTasks) {
              newSchedules?.forEach((cropSchedule, index) => {
                if (
                  slugify(cropSchedule?.category || '') === query.get('crop')
                ) {
                  setValue(index + 1);
                }
              });
              newSchedules = [
                {
                  cropName: <FormattedMessage id="label_todays_task" />,
                  imageUrl: '/icons/allCrops.svg',
                  inputsComplete: true,
                  tasks: allTasksForToday,
                  totalCurrentTask: allTasksForToday?.length,
                },
                ...newSchedules,
              ];
            } else {
              newSchedules?.forEach((cropSchedule, index) => {
                if (
                  slugify(cropSchedule?.category || '') === query.get('crop')
                ) {
                  setValue(index);
                }
              });
            }

            if (
              showTasks &&
              !allTasksForToday?.length &&
              newSchedules?.length > 1 &&
              !query.get('crop')
            ) {
              const cropName = slugify(newSchedules[1]?.category || '');
              query?.set('crop', cropName);
              return window?.location?.replace(
                `/crop-info?${query.toString()}`,
              );
            }

            if (!showTasks && newSchedules?.length) {
              const crop = query.get('crop');
              if (!crop) {
                const cropName = slugify(newSchedules[0]?.category || '');
                query?.set('crop', cropName);
                return window?.location?.replace(
                  `/crop-info?${query.toString()}`,
                );
              } else {
                let doesCropExist = false;
                newSchedules.forEach((schedule) => {
                  if (crop === slugify(schedule?.category)) {
                    doesCropExist = true;
                  }
                });

                if (!doesCropExist) {
                  const cropName = slugify(newSchedules[0]?.category || '');
                  query?.set('crop', cropName);
                  return window?.location?.replace(
                    `/crop-info?${query.toString()}`,
                  );
                }
              }
            }

            setCropSchedules(newSchedules);
            getCropRelatedData(newSchedules);
            setPageLoading('done');
          } catch (e) {
            console.log('Error: ', e);
            setPageLoading('error');
          }
        })();
      }
    }
  }, [search]);

  useEffect(() => {
    const showProductPrices =
      query.get('showProductPrices') === 'false' ? false : true;
    localStorage.setItem(`source`, source);
    localStorage.setItem(`role`, role);
    localStorage.setItem(`farmerId`, farmerId);
    localStorage.setItem(`profile`, profile);
    localStorage.setItem(`showProductPrices`, showProductPrices);
    localStorage.setItem(`fieldAgentMobileNumber`, fieldAgentMobileNumber);
    getCropRelatedData(cropSchedules);
  }, [value]);

  async function getCropRelatedData(cropSchedules) {
    const query = new URLSearchParams(window.location.search);
    const cropName = query.get('crop');
    if (cropName) {
      setLoading('skeleton');
      let newSchedulesData = cropSchedules.map(async (schedule) => {
        let skus = '';
        let posts = null;
        let videos = null;
        let issues = null;
        let products = null;
        if (slugify(cropName) === slugify(schedule?.category)) {
          const props = {
            state,
            language,
            mockDate,
            farmerId,
            mockAllTasks,
            farmerAuthToken,
            mockScheduleName,
            cropId: schedule?.cropId,
            cropName: schedule?.category,
          };
          if (source?.toLowerCase()?.includes('b2b')) {
            props.source = source;
          }

          if (!issues) {
            try {
              issues = await CropInfoService.getCropDetails(props);
              issues = issues?.responseData?.issues?.data?.content;
            } catch (e) {
              console.log('Failed to get crop details: ', e);
            }
          }
          if (!products) {
            try {
              products = await CropInfoService.getCropProducts(props);
              products = products?.responseData?.sections?.map((section) =>
                section?.items?.map((product) => {
                  skus += product?.skuCode + ',';
                  return {
                    ...product,
                    productImages: [
                      {
                        name: product?.productImages?.[0]?.name
                          ? product?.productImages?.[0]?.name + '?tr=h-125'
                          : '/icons/productImageDummy.jpeg',
                      },
                    ],
                  };
                }),
              );
            } catch (e) {
              console.log('Failed to get crop products: ', e);
            }
          }
          if (!posts && showPosts) {
            try {
              posts = await CropInfoService.getProblemSolvedPosts(props);
              posts = posts?.responseData?.posts;
            } catch (e) {
              console.log('Failed to get crop posts: ', e);
            }
          }
          if (!videos) {
            try {
              videos = await CropInfoService.getGoldVideos(props);
              videos = videos?.responseData?.videos;
              if (!videos) videos = [];
            } catch (e) {
              videos = [];
              console.log('Failed to get crop videos: ', e);
            }
          }

          return {
            ...schedule,
            skus,
            posts,
            videos,
            products,
            issues,
          };
        }
        return {
          ...schedule,
          skus,
          posts,
          videos,
          products,
          issues,
        };
      });
      newSchedulesData = await Promise.all(newSchedulesData);
      if (newSchedulesData?.length) {
        setCropSchedules(newSchedulesData);
      }
      setLoading('done');
    }
  }

  async function fieldAgentCropLogic() {
    const cropName = query.get('crop');
    const cropsResponse = await getCropsList(state, language);
    cropsResponse?.responseData?.children?.forEach((category) => {
      category?.children?.forEach((crop) => {
        if (crop?.category === cropName) {
          const cropDetails = {
            cropName: crop?.display,
            category: crop?.category,
            imageUrl: crop?.image
              ? crop?.image + '?tr=h-60'
              : '/icons/agrostar.png',
            cropId: crop?.id,
          };
          getCropRelatedData([cropDetails]);
          return setCropSchedules([cropDetails]);
        }
      });
    });
    setPageLoading('done');
  }

  const handleTabChange = (event, newValue) => {
    const query = new URLSearchParams(search);
    if (showTasks && newValue === 0) {
      query?.delete('crop', '');
    } else {
      query?.set('crop', slugify(cropSchedules?.[newValue]?.category || ''));
    }
    window?.history?.replaceState({}, null, `/crop-info?${query.toString()}`);

    setValue(newValue);
    ClevertapService.pushEvent(`Crops Tab Change Clicked`, {
      Crop: query.get('crop') ? query.get('crop') : 'All Crops',
    });
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  if (loadingStatus === 'loading' || pageLoading === 'started')
    return <Loader />;
  if (loadingStatus === 'error' || pageLoading === 'error')
    return <ServerError />;

  return (
    <div style={styles.pageWrapper}>
      <div style={styles.selectCrop}>
        <Typography sx={{ fontSize: '18px' }}>
          <FormattedMessage id="label_my_crops" />
        </Typography>

        {!(profile !== 'true' && role === 'FAG') && (
          <Link
            to={`${makeSelectYourCropsHref(
              match,
              search,
              language,
              state,
            )}&userId=${userId}&farmerId=${farmerId}&farmerAuthToken=${farmerAuthToken}&showHeader=false&appLinks=${appLinks}&role=${role}`}
            onClick={() =>
              ClevertapService.pushEvent(`Button Clicked`, {
                Type: 'Select Or Change Crop',
              })
            }
          >
            <Button sx={{ color: '#00733E' }} startIcon={<AddCircleOutlined />}>
              <FormattedMessage id="label_choose_or_change_crop" />
            </Button>
          </Link>
        )}
      </div>

      {!showTasks && !cropSchedules?.length ? (
        <ServerError text="Please add crop to see crop related information!" />
      ) : null}

      <CropsTabs
        value={value}
        isAndroid={isAndroid}
        handleChange={handleTabChange}
        cropSchedules={cropSchedules}
      />

      <TabPanel
        match={match}
        search={search}
        value={value}
        loading={loading}
        toggleDrawer={toggleDrawer}
        cropSchedules={cropSchedules}
      />

      {showTasks ? (
        <>
          <a
            href="ulink://android.agrostar.in/createPost"
            target="_blank"
            onClick={() =>
              ClevertapService.pushEvent(`Card Clicked`, {
                Type: 'Create Post',
              })
            }
          >
            <div style={styles.postCreationKisaan}>
              <Typography
                fontSize={14}
                fontWeight={700}
                sx={{ width: '88%', color: '#fff' }}
              >
                <FormattedMessage
                  id="label_advisory_message"
                  values={{
                    askAgriDoctor: (
                      <b style={{ color: '#FFD16F' }}>
                        <FormattedMessage id="label_ask_out_agri_doctor" />
                      </b>
                    ),
                  }}
                />
              </Typography>
              <Button
                variant="contained"
                sx={{
                  position: 'absolute',
                  fontSize: '12px',
                  fontWeight: '600',
                  bottom: '10px',
                  color: '#9D2123',
                  background: '#fff',
                  borderRadius: '8px',
                  '&:hover': { color: '#9D2123', background: '#fff' },
                }}
                startIcon={
                  <img
                    src="/icons/createPost.svg"
                    alt="createPost"
                    width="22px"
                    height="22px"
                  />
                }
              >
                <FormattedMessage id="label_create_post" />
              </Button>
            </div>
          </a>

          {getEnv('CROP_SCHEDULE_MOCK_DATE') === 'true' && (
            <>
              <div
                style={styles.technicalKisaan}
                id="land-here"
                onClick={toggleDrawer('bottom', true)}
              >
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  sx={{ color: '#fff' }}
                >
                  This is only for the purpose of testing Schedules to mock the
                  date for QA's, Stackholder's, Agronomy Team, etc. This will be
                  removed before going to prod.
                </Typography>
              </div>
              <MockDate
                query={query}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                toggleDrawer={toggleDrawer}
              />
            </>
          )}
        </>
      ) : null}
    </div>
  );
}

CropInfoPage.fetchData = async (match, search) => {
  const { language, state } = getRouteParams(match, search);
  const query = new URLSearchParams(search);
  const userId = query.get('userId');
  const farmerId = query.get('farmerId');
  const farmerAuthToken = query.get('farmerAuthToken');

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    languageURLs[lang] = `${makeCropInfoHref(
      match,
      search,
      lang,
    )}&userId=${userId}&farmerId=${farmerId}&farmerAuthToken=${farmerAuthToken}`;
  });

  const stateURLs = {};
  allStates.forEach((state) => {
    if (state.shop) {
      const lang = language === 'en' ? 'en' : state.language;
      stateURLs[state.slug] = `${makeCropInfoHref(
        match,
        search,
        lang,
        state.slug,
      )}&userId=${userId}&farmerId=${farmerId}&farmerAuthToken=${farmerAuthToken}`;
    } else {
      stateURLs[state.slug] = 'stateNa';
    }
  });

  return {
    initialData: {},
    titleId: 'label_crop_info',
    seoData: {},
    languageURLs,
    stateURLs,
    disableAnalytics: true,
  };
};

export default injectIntl(CropInfoPage);
